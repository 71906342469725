<template>
  <b-modal
    centered
    hide-footer
    no-close-on-backdrop
    v-model="modal.isOpen"
    @close="onCloseModal()"
    @hidden="onHiddeModal()"
  >
    <template #modal-title>Ingresar OTP</template>
    <div class="justify-content-center">
      <b-col cols="12" class="card-content">
        <h4 class="primary fw-bold text-center">Queremos confirmar que seas tú</h4>
        <p class="mt-4 fs-14" align="justify">
          Enviamos un código OTP a tu número de celular registrado terminado en ******<strong>{{
            digitosCelular
          }}</strong>
          ingrésalo en el siguiente campo para validar tu identidad:
        </p>
        <b-row class="pb-3">
          <b-col cols="12" class="text-center">
            <p class="fs-12 primary fw-bold mb-1">Ingresa aquí el código que llega a tu celular</p>
            <i class="las la-arrow-down icon-menu primary py-1"></i>
            <div class="mb-2 content-center">
              <b-form-input
                class="w-input-code"
                @keypress="onlyNumber"
                v-model="$v.codigo.$model"
                :state="$v.codigo.$dirty ? !$v.codigo.$error : null"
              />
            </div>
          </b-col>
          <b-col cols="12" class="text-center">
            <b-button variant="danger" class="mx-2" @click="sendForm()">
              <i class="las la-check-circle" /> Ingresar
            </b-button>
            <b-button variant="outline-danger" class="mx-2" @click="reenviarCodigo()" :disabled="contador > 0">
              <i class="las la-paper-plane" /> Reenviar Código {{ contador > 0 ? `(${contador})` : "" }}
            </b-button>
          </b-col>
        </b-row>
        <p class="mt-2 fs-14" align="justify">
          Si no es tu número registrado o cambiaste de línea de celular, comunícate con nosotros y actualiza tus datos a
          través de nuestras líneas de Servicio al Cliente:
        </p>
        <p class="mt-2 mb-0 primary fs-14 fw-bold"><i class="las la-phone dark" /> {{ numeroUnicoColombia }}</p>
        <p class="primary fs-14 fw-bold"><i class="lab la-whatsapp color-whatsapp" /> {{ numeroWhastappCartera }}</p>
      </b-col>
    </div>
  </b-modal>
</template>

<script>
import mixinsEvents from "@/app/shared/mixins/mixins-events";
import { required, numeric, maxLength, minLength } from "vuelidate/lib/validators";

export default {
  mixins: [mixinsEvents],
  props: {
    modal: {
      type: Object,
      default: () => {
        return { isOpen: false };
      },
    },
    digitosCelular: { type: String, default: null },
    tiempoReenvioCodigo: { type: Number, default: 10 },
  },
  data() {
    return {
      contador: 0,
      codigo: null,
      intervalContador: null,
    };
  },
  computed: {
    numeroWhastappCartera() {
      return this.$store.getters.infoContactoProgreser.whatsappCartera;
    },
    numeroUnicoColombia() {
      return this.$store.getters.infoContactoProgreser.numeroUnicoColombia;
    },
  },
  validations: {
    codigo: { required, numeric, minLength: minLength(4), maxLength: maxLength(4) },
  },
  methods: {
    iniciarContador() {
      this.contador = this.tiempoReenvioCodigo;
      this.intervalContador = setInterval(() => {
        if (this.contador > 0) {
          this.contador -= 1;
        } else {
          clearInterval(this.intervalContador);
        }
      }, 1000);
    },
    reenviarCodigo() {
      this.$emit("reenviarCodigo");
    },
    sendForm() {
      this.$v.$touch();
      if (this.$v.$invalid) return;

      this.$emit("sendForm", this.codigo);
    },
    onCloseModal() {
      this.$emit("useModal");
    },
    onHiddeModal() {
      this.codigo = null;
      setTimeout(() => this.$v.$reset(), 0);
    },
  },
};
</script>
